<template>
  <div class="introduction">
    <beacon-banner
      :bannerSrc="banner_4"
      title="示现一家有温度的文明企业"
      subTitle="Civilized  enterprise"
    ></beacon-banner>
    <div class="title">公司简介</div>
    <div class="content">
      <div class="desc">
        <p>
          安徽碧耕软件有限公司成于2014年,是一家集软件研发、人工智能应用开发和销售为一体的国家级高新技术企业，是合肥市高新区重点扶持的雏鹰企业，科创板挂牌企业(股权代码612649)，
          拥有自主知识产权50余项。公司研发团队核心成员来自中国科技大学，拥有近20年的餐饮行业信息化经验行先后参与过8000多家餐饮企业信息化建设。
        </p>
        <p>
          碧耕软件积极响应国家推进智慧城市和智慧食安的民生政策,与国内知名高校团队开展产学研深度融合,进行"大数据+食品安全标准化管控”的研发工作,按照国家《食品安全法》和《餐饮服务食品安全操作规范》，
          在行业内率先推出食安标准化管控平台,通过数据流程化处理和实时图片识别的技术手段对项目现场操作进行规范化、标准化,提供覆盖食安现场操作全流程的智慧食安管控解决方案,实现多层级智能预警食安现场管控的创新模式。平台自上线以来,迅速在多家知名团膳企业及单位落地应用，受到了各级政府的密切关注和高度认可，赢得了社会公众的高度共鸣和赞扬。
        </p>
        <p>
          立足现在，放眼未来，碧耕软件秉承“智慧食安、食安中国”的理念，以"天食安、食更安”为使命，深耕细作，不断提升产品和服务品质,成为食安领域的忠诚卫士和电子警察，做好政府食安的抓手、企业食安的助手、社会食安的推手,联合社会各界一起实现"食全食美食无忧”的美好时代。
        </p>
      </div>
      <div class="comp_img">
        <div class="img_wrap">
          <img :src="company_1" alt="" />
        </div>
        <div class="img_wrap">
          <img :src="company_2" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { banner_4, company_1, company_2 } from "@/utils/images";
export default {
  data() {
    return {
      banner_4,
      company_1,
      company_2
    }
  }
};
</script>

<style lang="scss" scoped>
.desc {
  font-size: 19px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 40px;
  color: #333333;
  p {
    text-indent: 2em;
    &:not(:first-child) {
      margin-top: 2.94%;
    }
  }
}

.comp_img {
  overflow: hidden;
}

.img_wrap {
  float: left;
  width: 48.85%;
  margin-top: 5.8%;
  &:last-child {
    margin-left: 2.3%;
  }
}

@media screen and (max-width: 992px) {
  .desc {
    font-size: 14vpx;
    line-height: 24vpx;
    p {
      &:not(:first-child) {
        margin-top: 30vpx;
      }
    }
  }
  .img_wrap {
    float: left;
    width: calc(50% - 7.5vpx);
    margin-top: 15vpx;
    &:last-child {
      margin-left: 15vpx;
    }
  }
}
</style>